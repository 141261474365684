var base = require('./base');

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    pid = $el.closest('.add-to-cart').data('pid');

    return pid;
}

/**
 * This will add a listener to set the height when the read more button is clicked.
 */
function addShowLessDescriptionListener() {
    const $productTileContainer = $('.product-tile');
    const $readmore = $productTileContainer.find('.read-more');

    $readmore.find('span.show-more, span.show-less').on('click', function () {
        const $parent = $(this).parent();
        const $description = $($parent).parent().find('.product-description');

        if ($(this).attr('class').includes('show-more')) {
            $(this).hide();
            $($parent).children('span.show-less').first().show();
            $($parent).children('span.show-less').first().removeClass('d-none');

            $description.find('.dots').hide();
            $description.find('.more-text').removeClass('d-none');
            $description.find('.more-text').show();
            $description.css('overflow-y', 'scroll');
            $description.css('padding-right', '10px');
        } else {
            $($parent).children('span.show-more').show();
            $(this).hide();
            $description.find('.dots').show();
            $description.find('.more-text').hide();
            $description.css('overflow-y', 'hidden');
            $description.css('padding-right', '0');
        }
    });
}

/**
 * This will execute if the add to cart button is clicked.
 */
function handleAddToCartClick() {
    $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
        var addToCartUrl;
        var pid;

        $('body').trigger('product:beforeAddToCart', this);

        pid = getPidValue($(this));

        addToCartUrl = base.getAddToCartUrl();

        var form = {
            pid: pid,
            quantity: 1
        };

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    base.handlePostCartAdd(data);
                    $('body').trigger('product:afterAddToCart', data);
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
}

module.exports = {
    init: function () {
        addShowLessDescriptionListener();
    },
    addToCart: handleAddToCartClick,
    showSpinner: function () {
        $('body').on('product:beforeAddToCart product:beforeAttributeSelect', function () {
            $.spinner().start();
        });
    }
};
